:root {
  --chat-row-template: 7.5rem 1fr 7.5rem;
  --room-link-label-font-size: 1.6rem;
  --composer-font-size: var(--room-link-label-font-size);
  --chat-line-font-size: var(--room-link-label-font-size);
  --chat-line-padding: 0.5rem 1rem;
}

@media (max-width: 960px) { /* Large Screens and below */
  :root {
    --chat-row-template: 5.5rem 1fr 7.5rem;
    --room-link-label-font-size: 1.4rem;
    --chat-line-padding: 0 1rem;
  }
}

/* Popup container - to display Room link is copied to clipboard */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  width: 250px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 0.6rem;
  padding: 0.8rem 0;
  position: absolute;
  z-index: 1;
  bottom: -5rem;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -0.5rem;
  border-width: 0.5rem;
  border-style: solid;
  border-color: transparent transparent #000 transparent;
}

.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Chat */
.chat {
  background: var(--color--tertiary);
  display: grid;
  grid-template-rows: var(--chat-row-template);
}

.chat__wrapper {
  overflow: hidden;
}

.chat__wrapper .messages {
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: 'Avenir' !important;
}
.chat__wrapper .messages p {   font-family: 'Avenir' !important;}
.chat__room-link {
  display: inline-flex;
  place-content: center;
  place-items: center;
}

.chat-line__link {
  color: var(--color-text-link);
  text-decoration: underline;
}

.room-link {
  background: var(--color-bg-input);
  padding: 0.5rem 1.5rem;
  border-radius: 40px;
  display: inline-flex;
  place-content: center;
  place-items: center;
}

.room-link__label {
  color: var(--color-text-base);
  font-size: var(--room-link-label-font-size);
  padding: 0 1rem 0 0;
}
/* .donate_popup {
  padding: 0;
  border-radius: 0;
  position: absolute;
  width: 85%;
  max-width: 520px;
  background-color: #fff;
  overflow: auto;
  max-height: 90vh;
}
.donate_popup .modal-head h2 {
  background-color: transparent!important;
  color: #393939;
  Padding: 15px!important;
  font-size: 1.2em;
} */
.chat-line {
  font-size: var(--chat-line-font-size);
  padding: var(--chat-line-padding);
}

.chime-web-composer {
  font-size: var(--composer-font-size);
  background: var(--color-bg-chat);
  padding: 1.5rem;
}

.bmpui-ui-watermark{
  display: none;
}

.flex_around{ display: flex; align-items: center; justify-content: space-between;}

.room-url{ width:100%; align-items: center; justify-content: space-between; border-radius:0!important;}

.flex_div{ display: flex; align-items: center;     justify-content: center;}

.room-link__label{ width:calc( 100% - 35px); text-overflow: ellipsis; overflow: hidden; white-space: normal; text-align: left; line-height: 1;; margin-bottom: 0;}

.chat_actions{ justify-content: space-evenly!important; padding: 1rem; display: flex; align-items: center; justify-content: space-between; border-bottom: 1px solid #454545; background-color: #474747;}

.chat_actions i { font-size: 18px!important;}

.like_btns a{color: #f5f5f5;}
.like_btns a:hover{color: #ffffff;}
.like_btns a.active, .chat_button a.active{color: #fff;}

.commonbtn{    
   display: inline-flex;
  place-items: center;
  font-size: 1.2rem;
  height: auto;
  color:#fff;
  font-weight: var(--font-weight-bold);
  background: rgba(0,0,0,0.8);
  padding: 0.5rem 1rem;
  border-radius: var(--radius-small);}

  .commonbtn i{ font-size:18px!important}

  .chat_header{ width: 100%; height: auto;}


#SuccessAddPopup ,#ErrorPopup{
  margin-left: 0!important;
    z-index: 999!important;
}
.flex_display{ display: flex; align-items: center; }
.space_between{justify-content: space-between;}
.col5{flex: 0 0 20%; max-width: 20%; -webkit-box-flex: 0; -ms-flex: 0 0 20%; position: relative; min-height: 1px; padding-right: 15px; padding-left: 15px;}
.wp_card{ width:100%;}
.wp_card .wp_head{ padding:10px; }
.wp_card .wp_head{ max-width: 100%; max-height: 100%; display: block; text-align: center; margin:0 auto;}
.wp_title{ font-size: 18px; height: 50px;}
.search_block{ position: relative; }
.search_block i{ position: absolute; top:10px; left:10px; color:#eee;}
.search_block input{ padding-left:35px;}
.chatpropstabs{  padding-top: 25px;}
.chat_button { color: #b3b2b2; font-size: 12px; text-align: center;}
.chat_button a { color: #b3b2b2; margin-bottom: 5px;}
.donate {     height: 30px; display: flex; align-items: center; font-size: 12px; background-color: #3072ca; color: #fff; border-radius: 3px; padding: 3px 5px;}
/* .material-icons {  font-size: 14px;  margin-right: 5px;} */
.chat_info {  padding: 0 12px; }
.chat_info h2 { font-size: 16px; margin: 0 0 10px 0; font-weight: 600;}
.chat_info .sub_hed { font-size: 13px; color: #c8c8c8;}
.chat_info .sub_hed span { margin-right: 5px;}
.chat_info p { font-size: 14px;}
.activity .activity_hed { border-bottom: solid 1px #474747;  padding: 0 12px 10px 12px; width: 100%; display: flex; align-items: center; justify-content: space-between;}
.activity .activity_hed .hedText h3 { font-size: 14px; margin-bottom: 5px;}
.activity .activity_hed .hedText p { font-size: 14px; color: #c2c2c2; margin: 0px;}
.activity .activity_hed .hedText .material-icons { font-size: 20px; margin-right: 10px;}
.activity .activity_hed .wp_close { color: #989898; height: 30px;}
.activity .activity_hed .wp_close .material-icons { font-size: 34px; }
.activity .activity_content { padding: 12px 12px;}
.activity .activity_content ul { margin: 0px; padding: 0px;}
.activity .activity_content ul li { align-items: center; list-style: none; display: flex; width: 100%; background-color: #181818; padding: 5px; border-radius: 5px; margin-bottom: 10px;}
.activity .activity_content ul li img { margin-right: 10px; width: 40px; height: 40px; border-radius: 100%;}
.activity .activity_content ul li p { margin: 0px; font-size: 13px; color:#797979;}
.activity .activity_content ul li p span {color:#b8b8b8; font-weight: 600; margin-left: 10px;}
.chat_block .activity_hed { border-bottom: solid 1px #474747;  padding: 0 12px 10px 12px; width: 100%; display: flex; align-items: center; justify-content: space-between;}
.chat_block .activity_hed .hedText h3 { font-size: 14px; margin-bottom: 5px;}
.chat_block .activity_hed .hedText p { font-size: 14px; color: #fff; margin: 0px;}
.chat_block .activity_hed .hedText .material-icons { color: #c2c2c2; font-size: 20px; margin-right: 10px;}
.chat_block .activity_hed .wp_close { color: #989898; height: 30px;}
.chat_block .activity_hed .wp_close .material-icons { font-size: 34px; }
.chat_block .activity_content { padding: 12px 12px;}
.chat_block .activity_content ul { margin: 0px; padding: 0px;}
.chat_block .activity_content ul li { align-items: center; list-style: none; display: flex; width: 100%; margin-bottom: 10px;}
.chat_block .activity_content ul li img { margin-right: 10px; width: 40px; height: 40px; border-radius: 100%;}
.chat_block .activity_content ul li p { margin: 0px; font-size: 13px; color:#797979; width: calc(100% - 50px);}
.chat_block .activity_content ul li p span {color:#b8b8b8; margin-left: 10px;}
.chat_block .activity_content ul li p span b { margin-right: 10px;}
.add_comment {display: flex; padding: 10px 12px !important; align-items: center; margin-bottom: 5px;}
.add_comment .comment_img { margin-right: 10px; width: 40px; height: 40px; border-radius: 100%;}
.add_comment .comment_box { border-radius: 20px;}
.add_comment .comment_box::-webkit-input-placeholder { font-size: 14px;  color: #a3a3a3;}
.add_comment .comment_box::-moz-placeholder { font-size: 14px;  color: #a3a3a3;}
.add_comment .comment_box:-ms-input-placeholder {   color: #a3a3a3;}
.add_comment .comment_box:-moz-placeholder { font-size: 14px;  color: #a3a3a3;}
.add_comment .comment_post {font-size: 14px; font-size: 14px; color: #6b869d;  position: absolute;  z-index: 9999;  right: 28px;}
.donate .material-icons { margin-right: 5px;}
.name_thumb { text-transform: uppercase; margin-right: 10px; width: 40px; height: 40px; border-radius: 100%; background-color: #3072ca; color: #fff;
  font-size: 13px; display: flex; align-items: center; justify-content: center; font-weight: 600;} 
  .name_bg2 { background-color: rgb(139, 0, 23); color: #fff;    } 
  .composer input {  width: 84%;}
  .clips_tabs .react-tabs__tab-list{ display: flex; align-items: center; flex-direction: row;}
  .add_clips{ width: 100%;}

  .add_clips h6{ color:#fff; font-size: 12px; margin-bottom: 3px; font-weight: bold; margin-top: 10px; letter-spacing: 1px;}
  .add_clips input, select{ width: 100%; height: 42px; border-radius: 3px; background-color: #000; color:#fff;  margin-bottom: 5px;}
  .add_clips input:focus{ border:1px solid rgb(116, 116, 116);}
  .clips_tabs .react-tabs__tab{ padding: 11px!important; text-align: center; background-color: #000; color:#fff; justify-content: center; font-size: 12px; line-height: 1; font-weight: bold;}
  .clips_tabs .react-tabs__tab--selected{ color:#000; background-color: #E9C449!important;}
  .btns_group { display: flex; align-items: center;}
  .color_btns{ background-color: #E9C449; padding: 7px 15px; color: #000; font-size: 13px; font-weight: bold; line-height: 1; height: inherit; border-radius: 2px; }
  .main-stage .bitmovinplayer-container.aspect-16x9:before{ padding-bottom:28%!important}
  .subclipplayer{
   margin-bottom: 10px;
  }
  .chat_type{ display: flex; align-items: center; justify-content: space-between; position: relative;}
  .chime-web-composer .chat_input{ width:calc(100% - 50px); border-radius: 50px; padding-right: 50px;}
.post_btn{ position: absolute; top: 26%; display: block; right: 16px; line-height: 1; padding: 3px; text-decoration: none!important; color: #8BB0FF!important; font-size: 13px;}
.post_btn:hover{ text-decoration:none; cursor:pointer;}
@media screen and (max-width: 800px) {
  .chat__wrapper { margin-top: 25px;}
}




